<template>
  <the-base-product-list-style>
    <template v-slot: product></template>
    <template v-slot:product_header>
      <h3>Отбеливающие компоненты</h3>
    </template>
    <template v-slot:product_title>
      <h5></h5>
    </template>
    <template v-slot:product_list>
<!-- Genowhite -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Genowhite</h6>
        </template>
        <template v-slot:product_text>
          <p>INCI: Acetyl Glycyl β-Alanine</p>
        </template>
      </the-base-product-item-style>
<!-- ET-VC -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>ET-VC</h6>
        </template>
        <template v-slot:product_text>
          <p>INCI: 3-O-Ethyl Ascorbic Acid</p>
        </template>
      </the-base-product-item-style>

<!-- Azeclair & Azeclair P -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Azeclair & Azeclair P</h6>
        </template>
        <template v-slot:product_text>
          <p>INCI: Potassium Azeloyl Diglycinate</p>
        </template>
      </the-base-product-item-style>

      <!-- Kojic Acid -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Kojic Acid</h6>
        </template>
        <template v-slot:product_text>
          <p></p>
        </template>
      </the-base-product-item-style>
      <!-- Ferulic Acid -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Ferulic Acid</h6>
        </template>
        <template v-slot:product_text>
          <p></p>
        </template>
      </the-base-product-item-style>
      <!-- DL-Mandelic Acid -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>DL-Mandelic Acid</h6>
        </template>
        <template v-slot:product_text>
          <p></p>
        </template>
      </the-base-product-item-style>
      <!-- Kojic Acid dipalmitate -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Kojic Acid dipalmitate</h6>
        </template>
        <template v-slot:product_text>
          <p></p>
        </template>
      </the-base-product-item-style>
      <!-- Magnesium ascorbyl phosphate -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Magnesium ascorbyl phosphate</h6>
        </template>
        <template v-slot:product_text>
          <p></p>
        </template>
      </the-base-product-item-style>
      <!-- Tranexamic Acid -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Tranexamic Acid</h6>
        </template>
        <template v-slot:product_text>
          <p></p>
        </template>
      </the-base-product-item-style>
      <!-- Glycolic Acid(70%) -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Glycolic Acid(70%)</h6>
        </template>
        <template v-slot:product_text>
          <p></p>
        </template>
      </the-base-product-item-style>
      <!-- Sodium Ascorbyl Phosphate -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Sodium Ascorbyl Phosphate</h6>
        </template>
        <template v-slot:product_text>
          <p></p>
        </template>
      </the-base-product-item-style>
      <!-- Nicotinamide -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Nicotinamide</h6>
        </template>
        <template v-slot:product_text>
          <p></p>
        </template>
      </the-base-product-item-style>
      <!-- Azelaic Acid -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6></h6>
        </template>
        <template v-slot:product_text>
          <p></p>
        </template>
      </the-base-product-item-style>
    </template>
  </the-base-product-list-style>
</template>