<template>
  <the-base-product-list-style>
    <template v-slot: product></template>
    <template v-slot:product_header>
      <h3>Активные ингредиенты</h3>
    </template>
    <template v-slot:product_title>
      <h5>Функциональные активы</h5>
    </template>
    <template v-slot:product_list>
      <!-- Epi-On -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Epi-On</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Azelamidopropyl Dimethyl Amine (and) Water (and) Butylene
            Glycol
          </p>
        </template>
      </the-base-product-item-style>

      <!-- IP-VC -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>IP-VC</h6>
        </template>
        <template v-slot:product_text>
          <p>INCI: Ascorbyl Tetraisopalmitate</p>
        </template>
      </the-base-product-item-style>

      <!-- ABS Acai Sterols EFA -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>ABS Acai Sterols EFA</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Euterpe Oleracea Sterols & Linoleic Acid & Oleic Acid &
            Linolenic Acid
          </p>
        </template>
      </the-base-product-item-style>
      <!-- AcquaSeal® Coconut -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>AcquaSeal® Coconut</h6>
        </template>
        <template v-slot:product_text>
          <p>INCI: Cocos Nucifera (Coconut) Fruit Extract & Tocopherol</p>
        </template>
      </the-base-product-item-style>
      <!-- ABS Amaranthus Squalane -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>ABS Amaranthus Squalane</h6>
        </template>
        <template v-slot:product_text>
          <p>INCI: Squalane</p>
        </template>
      </the-base-product-item-style>
      <!-- AC Vegetable Ceramides G -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>AC Vegetable Ceramides G</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Glycerin & Glycosphingolipids & Leuconostoc/Radish Root
            Ferment Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- AC Phytogel PF -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>AC Phytogel PF</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Hydrolyzed Fucus Vesiculosus Protein & Leuconostoc/Radish Root
            Ferment Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- ABS Rice Lipids Plus -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>ABS Rice Lipids Plus</h6>
        </template>
        <template v-slot:product_text>
          <p>INCI: Oryza Sativa (Rice) Lipids</p>
        </template>
      </the-base-product-item-style>
      <!-- AC Foaming Rice PF -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>AC Foaming Rice PF</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Aqua & Sodium Cocoyl Hydrolyzed Rice Protein &
            Leuconostoc/Radish Root Ferment Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- AC DermaPeptide Warming PF -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>AC DermaPeptide Warming PF</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Lactobacillus/Capsicum Frutescens Fruit Ferment Extract &
            Leuconcostoc/Radish Root Ferment Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- AC Fractionated Lemon Cooling -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>AC Fractionated Lemon Cooling</h6>
        </template>
        <template v-slot:product_text>
          <p>INCI: Citrus Limon (Lemon) Fruit Extract</p>
        </template>
      </the-base-product-item-style>
      <!-- ABS Ginger Mint Extract Cooling PF -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>ABS Ginger Mint Extract Cooling PF</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Aqua & Zingiber Officinale (Ginger) Root Extract & Mentha
            Piperita (Peppermint) Leaf Extract & Leuconostoc/Radish Root Ferment
            Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- AC Powdered Rose Quartz -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>AC Powdered Rose Quartz</h6>
        </template>
        <template v-slot:product_text>
          <p>INCI: Quartz</p>
        </template>
      </the-base-product-item-style>
      <!-- AC Sapphire Powder -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>AC Sapphire Powder</h6>
        </template>
        <template v-slot:product_text>
          <p>INCI: Sapphire Powder</p>
        </template>
      </the-base-product-item-style>
    </template>
  </the-base-product-list-style>
</template>

