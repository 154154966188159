<template>
  <the-base-product-list-style>
    <template v-slot: product></template>
    <template v-slot:product_header>
      <h3>Эмоленты</h3>
    </template>
    <template v-slot:product_header_description>
      <p>Эмоленты - смесь сложных химических веществ,</p>
      <p>которые оказывают смягчающий и увлажняющий эффект на кожу.</p>
    </template>
    <template v-slot:product_title>
      <h5></h5>
    </template>
    <template v-slot:product_list>
      <!-- Corum 5014/Lauryl/Myristyl Benzoate -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Corum 5014/Lauryl/Myristyl Benzoate</h6>
        </template>
        <template v-slot:product_text>
          <!-- <p>Corum 5014/Lauryl/Myristyl Benzoate</p> -->
          <p>
            Corum 5014 также известен как лаурил/миристилбензоат (C12-14
            алкилбензоат), который внесен в список IECIC 2021 года. Это
            смягчающее средство изготовлено из алкильной группы растительного
            происхождения и рекомендуется в качестве замены C12-15
            алкилбензоата, изопропилмиристата и изопропилпальмитата. Это
            солюбилизатор и фиксатор парфюмерных масел, смягчающий и
            диспергирующий агент для антиперспирантных продуктов, а также
            отличный солюбилизатор для УФ-фильтра. Рекомендуется для кремов и
            лосьонов для кожи, масел для ванн, антиперспирантов и солнцезащитных
            кремов.
          </p>
        </template>
      </the-base-product-item-style>

      <!-- Corum 5015/Diisostearyl Malate -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Corum 5015/Diisostearyl Malate</h6>
        </template>
        <template v-slot:product_text>
          <!-- <p>Corum 5015/Diisostearyl Malate</p> -->
          <p>
            Corum 5015 представляет собой диэфир многих разветвленных
            длинноцепочечных спиртов и яблочной кислоты. Это высокополярный
            сложный эфир из-за производного α-гидроксикислоты. Corum 5015
            обладает очень хорошей окислительной стабильностью и свойствами
            касторового масла. Обладает хорошей диспергируемостью пигментов и
            хорошей растворимостью в растительных, минеральных маслах и
            растворителях. Corum 5015 обладает хорошей водоудерживающей
            способностью. Его обычно используют в помадах, тональных основах,
            кремах и лосьонах.
          </p>
        </template>
      </the-base-product-item-style>

      <!-- Corum 5028/Myristyl Myristate -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Corum 5028/Myristyl Myristate</h6>
        </template>
        <template v-slot:product_text>
          <!-- <p>Corum 5028/Myristyl Myristate</p> -->
          <p>
            Corum 5028 — это нежирное твердое смягчающее средство, которое
            представляет собой комбинацию миристилового спирта и миристиновой
            кислоты, что приводит к улучшению вязкости, насыщенности и эстетики
            составов. Corum 5028 можно использовать в качестве соэмульгатора,
            который делает эмульсию более устойчивой к изменениям температуры.
            Он придает приятное, мягкое ощущение формулам. Он подходит для
            использования в широком диапазоне pH.
          </p>
        </template>
      </the-base-product-item-style>

      <!-- Corum 5041/Pentaerythrityl Tetraisostearate -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Corum 5041/Pentaerythrityl Tetraisostearate</h6>
        </template>
        <template v-slot:product_text>
          <!-- <p>Corum 5041/Pentaerythrityl Tetraisostearate</p> -->
          <p>
            Corum 5041 представляет собой высокомолекулярный эфир. Он
            обеспечивает длительное ощущение увлажненной кожи. Corum 5041
            помогает разгладить и смягчить кожу. При уходе за волосами образует
            глянцевую пленку, которая улучшает блеск волос. Кроме того, в
            помадах он создает блестящую пленку, которая устойчива к истиранию и
            уменьшает растрескивание при нанесении. Подходит для макияжа/помад,
            защитных и массажных кремов.
          </p>
        </template>
      </the-base-product-item-style>

      <!-- Corum 5047/Octyldodecyl Stearoyl Stearate -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Corum 5047/Octyldodecyl Stearoyl Stearate</h6>
        </template>
        <template v-slot:product_text>
          <!-- <p>Corum 5047/Octyldodecyl Stearoyl Stearate</p> -->
          <p>
            Corum 5047 придает коже длительное увлажнение и смягчение. Данный
            продукт является превосходным диспергатором и связующим компонентом
            для пигмента. Corum 5047 обычно используется в кремах и лосьонах. Он
            также подходит для помад и макияжа глаз.
          </p>
        </template>
      </the-base-product-item-style>

      <!-- Corum 5081/Phytosteryl Hydroxystearate -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Corum 5081/Phytosteryl Hydroxystearate</h6>
        </template>
        <template v-slot:product_text>
          <!-- <p>Corum 5081/Phytosteryl Hydroxystearate -->
          <p>
            Corum 5081 представляет собой растительный моноэфир холестерина и
            гидроксистеариновой кислоты. Он похож на межклеточный липид и
            обладает водоудерживающей способностью. Corum 5081 используется в
            качестве увлажняющего средства. Его обычно используют в помадах,
            тональных кремах, лосьонах.
          </p>
        </template>
      </the-base-product-item-style>

      <!-- C12-15 Alkyl Benzoate -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>C12-15 Alkyl Benzoate</h6>
        </template>
        <template v-slot:product_text>
          <!-- <p>C12-15 Alkyl Benzoate</p> -->
          <p>
            Алкилбензоат C12-15 — прозрачная жидкость, которая производится из
            бензойной кислоты синтетическим путём. Вещество используется в
            качестве смягчающего средства, усилителя текстуры и средства для
            кондиционирования кожи в косметических препаратах. Этот ингредиент
            известен своей способностью придавать продуктам легкий, нежирный вид
            и может способствовать растеканию и гладкости кремов и лосьонов.
          </p>
        </template>
      </the-base-product-item-style>
      <!-- Caprylic/Capric Triglyceride -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Caprylic/Capric Triglyceride</h6>
        </template>
        <template v-slot:product_text>
          <!-- <p>Caprylic/Capric Triglyceride</p> -->
          <p>
            Каприловый триглицерид является органическим эмолентом. Данный
            компонент обладает хорошей совместимостью с кожей, отлично смягчает
            и поэтому особенно показан для сухой и обезвоженной кожи,
            способствует сохранению влаги в коже, защищает кожу и волосы от
            негативных воздействий, препятствует возникновению чувства
            стянутости и сухости кожи, часто используется как основа для кремов,
            некомедогенен в отличие от масел, несет противовоспалительное
            действие и служит проводником для доступа жирорастворимых активных
            компонентов в кожу.
          </p>
        </template>
      </the-base-product-item-style>
      <!-- Coco-Caprylate/Caprate -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Coco-Caprylate/Caprate</h6>
        </template>
        <template v-slot:product_text>
          <!-- <p>Coco-Caprylate/Caprate</p> -->
          <p>
            Коко-каприлат/капрат - кокосиликон. Натуральный эмолент, смягчитель,
            кондиционер, аналог кожного жира. Его получают путём этерификации
            кокосового спирта с каприловой и каприновой кислотами. Используется
            в косметических средствах для кожи и волос, в пляжных средствах, в
            средствах для массажа. Увлажняет и смягчает кожу, придаёт блеск
            волосам.
          </p>
        </template>
      </the-base-product-item-style>
      <!-- Ethylhexyl Isononanoate -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Ethylhexyl Isononanoate</h6>
        </template>
        <template v-slot:product_text>
          <!-- <p>Ethylhexyl Isononanoate</p> -->
          <p>
            Этилгексил Изонаноноат - смягчающее средство. Образует на
            поверхности тканей защитную пленку, которая помогает предотвратить
            испарение естественной влаги.
          </p>
        </template>
      </the-base-product-item-style>
      <!-- Ethylhexyl Palmitate -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Ethylhexyl Palmitate</h6>
        </template>
        <template v-slot:product_text>
          <!-- <p>Ethylhexyl Palmitate</p> -->
          <p>
            Этилгексилпальмитат - сложный эфир 2-этилгексилового спирта и
            пальмитиновой кислоты. Прозрачная, маслянистая, бесцветная жидкость.
            Хорошо смешивается с органическими жидкостями. Способствует
            повышению вязкости композиций. В косметических средствах
            используется в качестве соэмульгатора и маслозаменителя,
            растворителя, смачивателя, как фиксатор аромата. Придает коже
            ощущение гладкости и ухоженности. Применяется в качестве эмолента в
            средствах по уходу за кожей, детской косметике, солнцезащитных
            средствах, кондиционерах для волос.
          </p>
        </template>
      </the-base-product-item-style>
      <!-- Ethylhexyl Stearate -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Ethylhexyl Stearate</h6>
        </template>
        <template v-slot:product_text>
          <!-- <p>Ethylhexyl Stearate</p> -->
          <p>
            Этилгексилстеарат - смягчающее средство,натуральный растительный
            ингредиент, растворитель на масляной основе, предотвращает излишнюю
            потерю влаги с кожи и защищает сам продукт от высыхания.
          </p>
        </template>
      </the-base-product-item-style>
      <!-- Isopropyl Isostearate -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Isopropyl Isostearate</h6>
        </template>
        <template v-slot:product_text>
          <!-- <p>Isopropyl Isostearate</p> -->
          <p>
            Изопропилстеарат - сложный эфир изопропилового спирта и жирных
            кислот стеариновой кислоты или изостеариновой. В косметических
            кремах, лосьонах, декоративных изделиях применяется как эмолент и
            растворитель. Обеспечивает легкую и нежирную консистенцию рецептур.
            Хорошо распределяется по поверхности кожи, придавая ей эластичность.
          </p>
        </template>
      </the-base-product-item-style>
      <!-- Isostearyl Isostearate -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Isostearyl Isostearate</h6>
        </template>
        <template v-slot:product_text>
          <!-- <p>Isostearyl Isostearate</p> -->
          <p>
            Изостеарил изостеарат - эфир насыщенной жирной кислоты и спирта.
            Смягчает и увлажняет кожу. Увеличивает барьерные свойства кожи.
            Применяется во всех типах косметических средств, в том числе в
            детской косметике. Хороший диспергатор для красителей поэтому широко
            применяется в декоративной косметике.
          </p>
        </template>
      </the-base-product-item-style>
      <!-- Isopropyl Myristate -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Isopropyl Myristate</h6>
        </template>
        <template v-slot:product_text>
          <!-- <p>Isopropyl Myristate</p> -->
          <p>
            Изопропилмиристат — продукт взаимодействия изопропилового спирта и
            миристиновой кислоты, который широко используется в парфюмерной и
            косметической промышленности в качестве растворителя и эмолента,
            придающего коже гладкость и мягкость.
          </p>
        </template>
      </the-base-product-item-style>
      <!-- Isopropyl Palmitate -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Isopropyl Palmitate</h6>
        </template>
        <template v-slot:product_text>
          <!-- <p>Isopropyl Palmitate</p> -->
          <p>
            Изопропилпальмитат - сложный эфир изопропилового спирта и
            пальмитиновой кислоты. Бесцветная прозрачная жидкость без запаха.
            Обладает смягчающим действием, используется как жировой и
            платифицирующий компонент в кремах, лосьонах, губной помаде.
          </p>
        </template>
      </the-base-product-item-style>
      <!-- Isodecyl Neopentanoate -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Isodecyl Neopentanoate</h6>
        </template>
        <template v-slot:product_text>
          <!-- <p>Isodecyl Neopentanoate</p> -->
          <p>
            Изодецил неопентаноат - химическое соединение изодецилового спирта и
            неопентановой кислоты. Эффективный природный эмолент. Изодецил
            неопентаноат помогает улучшить текстуру и распределение продуктов,
            делая кожу гладкой и мягкой. Он также используется в качестве
            связующего вещества и растворителя в парфюмерных композициях.
          </p>
        </template>
      </the-base-product-item-style>
      <!-- Pentaerythrityl Tetraisostearate -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Pentaerythrityl Tetraisostearate</h6>
        </template>
        <template v-slot:product_text>
          <!-- <p>Pentaerythrityl Tetraisostearate</p> -->
          <p>
            Пентаэритритил тетраизостеарат - умеренно вязкая жидкость. Эмолент.
            Обеспечивает приятное ощущение мягкости кожи. Обладает
            водоотталкивающими свойствами. Смачивающий агент для пигментов и УФ
            фильтров. Хорошая альтернатива касторового масла. Используется при
            производстве кремов и лосьонов для кожи, солнцезащитной косметике и
            маслах после душа.
          </p>
        </template>
      </the-base-product-item-style>
      <!-- Triisostearin -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Triisostearin</h6>
        </template>
        <template v-slot:product_text>
          <!-- <p>Triisostearin</p> -->
          <p>
            Триизостеарин - cмягчающее средство, эмолент, диспергатор пигментов
            и красителей. Используется про производстве антиперспирантов,
            средств по уходу за кожей, солнцезащитной и декоративной косметике.
          </p>
        </template>
      </the-base-product-item-style>
      <!-- Triethylhexanoin -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Triethylhexanoin</h6>
        </template>
        <template v-slot:product_text>
          <!-- <p>Triethylhexanoin</p> -->
          <p>
            Триэтилгексаноин - эмолент, смягчающий агент, антистатик,
            растворитель, маскирует запах основы, увеличивает вязкость
            продуктов. Используется в средствах для кожи и волос как
            кондиционер.
          </p>
        </template>
      </the-base-product-item-style>
      <!-- Trimethylolpropane Triisostearate -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Trimethylolpropane Triisostearate</h6>
        </template>
        <template v-slot:product_text>
          <!-- <p>Trimethylolpropane Triisostearate</p> -->
          <p>
            Триметилолпропан триизостеарат представляет собой сложный эфир
            изостеариновой кислоты и триметилолпропана.Триметилолпропан
            Триизостеарат широко используется в косметике и средствах личной
            гигиены в качестве смягчающего и кондиционирующего средства для
            кожи. Это помогает улучшить текстуру и ощущение продуктов, таких как
            помады, бальзамы для губ и другие средства по уходу за кожей. Кроме
            того, триметилолпропан триизостеарат обладает увлажняющими
            свойствами, которые могут увлажнять и защищать клетки кожи.
          </p>
        </template>
      </the-base-product-item-style>
    </template>
  </the-base-product-list-style>
</template>