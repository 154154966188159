<template>
  <!-- Base Style For Component's Page -->
  <the-base-style>
    <template v-slot:background>
      <div class="background_cosmetic"></div>
    </template>

    <template v-slot:base_header>
      <h2>Косметическая отрасль</h2>
    </template>
    <template v-slot:base_list>
      <!-- Card for Active Ingredients -->
      <the-base-card-style>
        <template v-slot:base_item></template>
        <template v-slot:base_icon>
          <!-- SVG Icon for Active Ingredients -->
          <svg
            class="svg_image"
            xmlns="http://www.w3.org/2000/svg"
            xml:space="preserve"
            width="18.75mm"
            height="18.75mm"
            version="1.1"
            style="
              shape-rendering: geometricPrecision;
              text-rendering: geometricPrecision;
              image-rendering: optimizeQuality;
              fill-rule: evenodd;
              clip-rule: evenodd;
            "
            viewBox="0 0 219.46 219.46"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xmlns:xodm="http://www.corel.com/coreldraw/odm/2003"
          >
            <g id="Слой_x0020_1">
              <metadata id="CorelCorpID_0Corel-Layer" />
              <g id="_2632372605808">
                <g>
                  <path
                    class="fil0"
                    d="M109.73 12.39c53.67,0 97.34,43.67 97.34,97.34 0,53.67 -43.67,97.34 -97.34,97.34 -53.67,0 -97.34,-43.67 -97.34,-97.34 0,-53.67 43.67,-97.34 97.34,-97.34l0 0zm0 -12.39c-60.6,0 -109.73,49.13 -109.73,109.73 0,60.6 49.13,109.73 109.73,109.73 60.6,0 109.73,-49.13 109.73,-109.73 0,-60.6 -49.13,-109.73 -109.73,-109.73l0 0z"
                  />
                </g>
              </g>
              <g id="_2632372616176">
                <path
                  class="activeIngrfil1 activeIngrstr0"
                  d="M81.48 171.07c19.69,-23.75 -11.25,-58.9 6.41,-78.75 22.34,-25.11 52.81,10.47 81.56,-9.06"
                />
                <path
                  class="activeIngrfil1 activeIngrstr0"
                  d="M132.72 110.59c1.91,-19.48 -14.95,-42.26 -0.15,-64.06"
                />
                <path
                  class="activeIngrfil1 activeIngrstr0"
                  d="M44.76 134.51c18.95,-15.71 45.16,0.81 65.06,-0.84"
                />
                <circle
                  class="activeIngrfil1 activeIngrstr0"
                  cx="139"
                  cy="140.59"
                  r="29.99"
                />
                <line
                  class="activeIngrfil1 activeIngrstr0"
                  x1="55.7"
                  y1="138.26"
                  x2="77.82"
                  y2="160.38"
                />
                <line
                  class="activeIngrfil1 activeIngrstr0"
                  x1="70"
                  y1="135.52"
                  x2="79.94"
                  y2="145.47"
                />
                <line
                  class="activeIngrfil1 activeIngrstr0"
                  x1="91.4"
                  y1="101.11"
                  x2="113.53"
                  y2="123.23"
                />
                <line
                  class="activeIngrfil1 activeIngrstr0"
                  x1="100.13"
                  y1="93.24"
                  x2="122.25"
                  y2="115.36"
                />
                <line
                  class="activeIngrfil1 activeIngrstr0"
                  x1="115.63"
                  y1="91.42"
                  x2="125.48"
                  y2="101.27"
                />
                <line
                  class="activeIngrfil1 activeIngrstr0"
                  x1="89.7"
                  y1="116.58"
                  x2="100.13"
                  y2="127.01"
                />
                <line
                  class="activeIngrfil1 activeIngrstr0"
                  x1="134.91"
                  y1="71.7"
                  x2="145.28"
                  y2="82.06"
                />
                <line
                  class="activeIngrfil1 activeIngrstr0"
                  x1="137.2"
                  y1="57.1"
                  x2="159.32"
                  y2="79.22"
                />
                <path
                  class="activeIngrfil0 activeIngrstr0"
                  d="M139.23 160.38c8.15,0 14.75,-4.86 14.75,-15.97 0,-11.11 -14.75,-24.28 -14.75,-24.28 0,0 -14.75,13.16 -14.75,24.28 0,11.11 6.6,15.97 14.75,15.97z"
                />
              </g>
            </g>
          </svg>
        </template>
        <template v-slot:base_title>Активные ингредиенты</template>
        <template v-slot:base_description>
          <div class="base_description">
            <router-link
              v-on:click="scrollToTop"
              class="base_element_active"
              to="/bioenzymes"
            >
              <li>Биоферменты</li></router-link
            >
            <router-link
              v-on:click="scrollToTop"
              class="base_element_active"
              to="/botanicalExtracts"
              ><li>Ботанические экстракты</li></router-link
            >
            <router-link
              v-on:click="scrollToTop"
              class="base_element_active"
              to="/complexAssets"
              ><li>Комплексные активы</li></router-link
            >
            <router-link
              v-on:click="scrollToTop"
              class="base_element_active"
              to="/assetsDeliverySystems"
              ><li>Системы доставки активов</li></router-link
            >
            <router-link
              v-on:click="scrollToTop"
              class="base_element_active"
              to="/functionalAssets"
              ><li>Функциональные активы</li></router-link
            >
            <router-link
              v-on:click="scrollToTop"
              class="base_element_active"
              to="/enzymes"
              ><li>Ферменты</li></router-link
            >
            <router-link
              v-on:click="scrollToTop"
              class="base_element_active"
              to="/proteins"
            >
              <li>Протеины</li></router-link
            >
          </div>
        </template>
      </the-base-card-style>
      <!-- Card for Emollients -->
      <the-base-card-style>
        <template v-slot:base_item></template>
        <template v-slot:base_icon>
          <!-- SVG Icon for Emollients -->
          <svg
            class="svg_image"
            xmlns="http://www.w3.org/2000/svg"
            xml:space="preserve"
            width="18.75mm"
            height="18.75mm"
            version="1.1"
            style="
              shape-rendering: geometricPrecision;
              text-rendering: geometricPrecision;
              image-rendering: optimizeQuality;
              fill-rule: evenodd;
              clip-rule: evenodd;
            "
            viewBox="0 0 96.66 96.66"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xmlns:xodm="http://www.corel.com/coreldraw/odm/2003"
          >
            <g id="Слой_x0020_1">
              <metadata id="CorelCorpID_0Corel-Layer" />
              <g id="_2632828656368">
                <g>
                  <path
                    class="emollient2"
                    d="M48.33 5.46c23.64,0 42.87,19.23 42.87,42.87 0,23.64 -19.23,42.87 -42.87,42.87 -23.64,0 -42.87,-19.23 -42.87,-42.87 0,-23.64 19.23,-42.87 42.87,-42.87l0 0zm0 -5.46c-26.69,0 -48.33,21.64 -48.33,48.33 0,26.69 21.64,48.33 48.33,48.33 26.69,0 48.33,-21.64 48.33,-48.33 0,-26.69 -21.64,-48.33 -48.33,-48.33l0 0z"
                  />
                </g>
              </g>
              <path
                class="emollient2"
                d="M69.82 57.43c-1.39,-5.84 -6.6,-15.96 -9.95,-21.96 -0.33,-0.59 -1.17,-0.59 -1.5,0 -3.89,6.98 -10.3,19.52 -10.3,24.43 0,6.63 4.96,12.03 11.05,12.03 4.62,0 8.58,-3.11 10.23,-7.5 0.02,-0.05 0.03,-0.09 0.04,-0.14 0.39,-1.55 0.61,-3.16 0.61,-4.83 0,-0.62 -0.07,-1.31 -0.19,-2.04zm-1.86 6.41c-0.01,0.05 -0.03,0.11 -0.05,0.16 -1.5,3.91 -4.94,6.43 -8.78,6.43 -5.26,0 -9.54,-4.72 -9.54,-10.52 0,-3.57 4.14,-12.7 8.78,-21.28 0.33,-0.6 1.19,-0.6 1.52,-0 3.56,6.59 7.34,14.47 8.46,19.1 0.11,0.69 0.16,1.26 0.16,1.74 0,1.42 -0.18,2.89 -0.55,4.37z"
              />
              <path
                class="emollient2"
                d="M45.69 59.91c0,-6.99 10.04,-24.72 11.96,-28.05 0.16,-0.27 0.15,-0.59 -0.01,-0.86l-3.6 -6.05c-0.33,-0.56 -1.13,-0.57 -1.47,-0.01 -4.45,7.29 -16.04,27.02 -16.04,34.74 0,9.32 7.56,16.9 16.85,16.9 3.57,0 6.9,-2.28 5.45,-2.28 -7.24,0 -13.14,-6.45 -13.14,-14.39z"
              />
              <path
                class="emollient2"
                d="M53.39 78.84c-10.48,0 -19.01,-8.59 -19.01,-19.16 0,-9.13 13.96,-32.09 17.38,-37.58 0.17,-0.28 0.17,-0.63 -0.01,-0.9 -1.17,-1.86 -2.12,-3.34 -2.72,-4.26 -0.33,-0.52 -1.08,-0.52 -1.42,-0 -3.71,5.7 -20.69,32.39 -20.69,42.54 0,11.4 9.58,20.63 21.4,20.63 2.85,0 6.09,-1.26 5.07,-1.26zm-24.96 -19.37c0,-8.82 13.93,-31.5 19.18,-39.74 0.33,-0.52 1.09,-0.51 1.43,0.01l0.93 1.46c0.18,0.28 0.18,0.62 0.01,0.89 -4.89,7.9 -17.11,28.56 -17.11,37.58 0,7.75 4.26,14.52 10.55,18.06 0.13,0.07 0.06,0.28 -0.09,0.24 -8.56,-2.14 -14.89,-9.62 -14.89,-18.51z"
              />
            </g>
          </svg>
        </template>
        <template v-slot:base_title_active
          ><router-link v-on:click="scrollToTop" to="/cosmeticEmollient"
            >Эмоленты</router-link
          >
        </template>
        <template v-slot:base_description>
          <div class="base_element">
            <li>C12-15 Alkyl Benzoate</li>
          </div>
          <div class="base_element">
            <li>Caprylic/Capric Triglyceride</li>
          </div>
          <div class="base_element">
            <li>Coco-Caprylate/Caprate</li>
          </div>
          <div class="base_element">
            <li>Ethylhexyl Isononanoate</li>
          </div>
          <div class="base_element">
            <li>Ethylhexyl Palmitate</li>
          </div>
          <div class="base_element">
            <li>Ethylhexyl Stearate</li>
          </div>
          <div class="base_element">
            <li>Isopropyl Isostearate</li>
          </div>
          <div class="base_element">
            <li>Isopropyl Myristate</li>
          </div>
          <div class="base_element">
            <li>Isopropyl Palmitate</li>
          </div>
          <div class="base_element">
            <p>
              Полный список эмолентов смотрите в Разделе
              <router-link v-on:click="scrollToTop" to="/CosmeticEmollient"
                >Эмоленты</router-link
              >
            </p>
          </div>
          
        </template>
      </the-base-card-style>
      <!-- Card for Fermentative Oil -->
      <the-base-card-style>
        <template v-slot:base_item></template>
        <template v-slot:base_icon>
          <!-- SVG Icon for FermentOil -->
          <svg
            class="svg_image"
            xmlns="http://www.w3.org/2000/svg"
            xml:space="preserve"
            width="18.75mm"
            height="18.75mm"
            version="1.1"
            style="
              shape-rendering: geometricPrecision;
              text-rendering: geometricPrecision;
              image-rendering: optimizeQuality;
              fill-rule: evenodd;
              clip-rule: evenodd;
            "
            viewBox="0 0 141.58 141.58"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xmlns:xodm="http://www.corel.com/coreldraw/odm/2003"
          >
            <g id="Слой_x0020_1">
              <metadata id="CorelCorpID_0Corel-Layer" />
              <path
                class="fermentOilfil0 fermentOilstr0"
                d="M62.89 97.91c14.59,0 29.19,0 29.19,0 1.06,0 1.74,-1.12 1.26,-2.06l-20.6 -38.33c-1.4,-2.75 -2.13,-5.78 -2.13,-8.87l0.23 -14.17c0,-3.08 3.05,-6.04 3.05,-6.04 0.16,-0.24 -0.01,-0.56 -0.3,-0.56l-21.4 0c-0.29,0 -0.46,0.32 -0.3,0.56 0,0 3.05,2.96 3.05,6.04l0.23 14.17c0,3.08 -0.73,6.12 -2.13,8.87l-20.6 38.33c-0.48,0.94 0.2,2.06 1.26,2.06 0,-0 14.59,-0 29.19,-0z"
              />
              <line
                class="fermentOilfil0 fermentOilstr0"
                x1="55.17"
                y1="48.65"
                x2="70.61"
                y2="48.65"
              />
              <g id="_2631861440048">
                <g>
                  <path
                    class="fermentOilfil1"
                    d="M70.79 7.99c34.63,0 62.8,28.17 62.8,62.8 0,34.63 -28.17,62.8 -62.8,62.8 -34.63,0 -62.8,-28.17 -62.8,-62.8 0,-34.63 28.17,-62.8 62.8,-62.8l0 0zm0 -7.99c-39.1,0 -70.79,31.69 -70.79,70.79 0,39.1 31.69,70.79 70.79,70.79 39.1,0 70.79,-31.69 70.79,-70.79 0,-39.09 -31.69,-70.79 -70.79,-70.79l0 0z"
                  />
                </g>
              </g>
              <g id="_2631861449744">
                <path
                  class="fermentOilfil1"
                  d="M64.82 112.81c-0,0.5 0.34,0.93 0.82,1.05 9.86,2.39 18.12,4.25 27.29,-1.82 9.08,-6 13.84,-16.64 18.86,-26.14 0.31,-0.58 0.02,-1.3 -0.6,-1.51 -11.78,-3.9 -24.8,-6.79 -35.58,0.13 -10.87,6.97 -10.69,17 -10.79,28.28z"
                />
                <g>
                  <path
                    class="fermentOilfil2"
                    d="M100.59 90.37c-14.17,7.71 -26.34,17.93 -39.65,26.92 0,0 -0.21,-0.21 -0.21,-0.21 4.55,-6.86 11.27,-11.78 18.29,-15.83 6.92,-4.18 14.05,-7.96 21.44,-11.16l0.13 0.27 0 0z"
                  />
                </g>
                <g>
                  <path
                    class="fermentOilfil1"
                    d="M60.58 103.69c-0.48,0 -0.95,-0.17 -1.32,-0.47 -5.89,-4.81 -8.22,-10.16 -7.12,-16.36 1.17,-6.61 5.84,-13.13 10.12,-18.73 0.39,-0.51 0.99,-0.81 1.63,-0.81 0.56,0 1.09,0.22 1.48,0.63 7.77,8.06 11.09,15.14 10.14,21.66 -1.26,8.64 -8.01,11.73 -14.24,13.96 -0.23,0.08 -0.46,0.12 -0.7,0.12l0 0z"
                  />
                  <g>
                    <path
                      class="fermentOilfil2"
                      d="M63.9 68.33c0.28,0 0.55,0.11 0.76,0.33 5.47,5.67 11,13.07 9.87,20.82 -1.16,7.92 -7.15,10.86 -13.59,13.16 -0.12,0.04 -0.24,0.06 -0.36,0.06 -0.25,0 -0.49,-0.08 -0.69,-0.24 -4.83,-3.95 -7.95,-8.77 -6.77,-15.41 1.14,-6.46 5.83,-12.94 9.93,-18.3 0.21,-0.28 0.53,-0.42 0.84,-0.42l0 0zm0 -2c-0.96,0 -1.85,0.44 -2.43,1.2 -4.35,5.69 -9.1,12.34 -10.31,19.16 -1.17,6.59 1.28,12.25 7.47,17.3 0.55,0.45 1.24,0.69 1.95,0.69 0.35,0 0.7,-0.06 1.03,-0.18 6.5,-2.32 13.55,-5.57 14.89,-14.75 1,-6.84 -2.41,-14.19 -10.41,-22.49 -0.58,-0.6 -1.36,-0.94 -2.2,-0.94l0 0z"
                    />
                  </g>
                </g>
                <g>
                  <path
                    class="fermentOilfil2"
                    d="M63.85 83.65c-0.1,5.82 -0.22,11.77 -1.66,17.45 -0.63,1.86 -1.42,3.76 -2.82,5.25l-0.18 -0.09c0.57,-3.37 1.53,-7.97 2.42,-11.22 0.81,-3.75 1.47,-7.57 2.03,-11.4l0.2 0.01 0 0z"
                  />
                </g>
              </g>
            </g>
          </svg>
        </template>
        <template v-slot:base_title_active
          ><router-link v-on:click="scrollToTop" to="/fermentOil"
            >Ферментативные масла</router-link
          >
        </template>
        <template v-slot:base_description>
          <div class="base_element">
            <li>Fermentoil Avocado</li>
          </div>
          <div class="base_element">
            <li>Fermentoil Angelica</li>
          </div>
          <div class="base_element">
            <li>Fermentoil Argan</li>
          </div>
          <div class="base_element">
            <li>Fermentoil Artemisia</li>
          </div>
          <div class="base_element">
            <li>Fermentoil Camellia seed</li>
          </div>
          <div class="base_element">
            <li>Fermentoil Evening primrose</li>
          </div>
          <div class="base_element">
            <li>Fermentoil Green tea seed</li>
          </div>
          <div class="base_element">
            <li>Fermentoil Glycyrrhiza</li>
          </div>
          <div class="base_element">
            <li>Fermentoil Olive</li>
          </div>
          <div class="base_element">
            <p>
              Полный список Ферментативных масел смотрите в Разделе
              <router-link v-on:click="scrollToTop" to="/FermentOil"
                >Ферментативные масла</router-link
              >
            </p>
          </div>
        </template>
      </the-base-card-style>
      <!-- Card for Cosmetic Emulsifiers -->
      <the-base-card-style>
        <template v-slot:base_item></template>
        <template v-slot:base_icon>
          <!-- SVG Icon for Emulsifiers -->
          <svg
            class="svg_image"
            xmlns="http://www.w3.org/2000/svg"
            xml:space="preserve"
            width="18.75mm"
            height="18.75mm"
            version="1.1"
            style="
              shape-rendering: geometricPrecision;
              text-rendering: geometricPrecision;
              image-rendering: optimizeQuality;
              fill-rule: evenodd;
              clip-rule: evenodd;
            "
            viewBox="0 0 90.45 90.45"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xmlns:xodm="http://www.corel.com/coreldraw/odm/2003"
          >
            <g id="Слой_x0020_1">
              <metadata id="CorelCorpID_0Corel-Layer" />
              <g id="_2631748838832">
                <g>
                  <path
                    class="emulsifierfil0"
                    d="M45.22 5.11c22.12,0 40.12,18 40.12,40.12 0,22.12 -18,40.12 -40.12,40.12 -22.12,0 -40.12,-18 -40.12,-40.12 0,-22.12 18,-40.12 40.12,-40.12l0 0zm0 -5.11c-24.98,0 -45.22,20.25 -45.22,45.22 0,24.98 20.25,45.22 45.22,45.22 24.98,0 45.22,-20.25 45.22,-45.22 0,-24.98 -20.25,-45.22 -45.22,-45.22l0 0z"
                  />
                </g>
              </g>
              <circle
                class="emulsifierfil0 emulsifierstr0"
                cx="45.22"
                cy="45.22"
                r="18.09"
              />
              <circle
                class="emulsifierfil1 emulsifierstr0"
                cx="45.22"
                cy="45.22"
                r="27.13"
              />
              <circle class="emulsifierfil0" cx="72.36" cy="45.22" r="2.55" />
              <circle class="emulsifierfil0" cx="71.26" cy="53.26" r="2.55" />
              <circle class="emulsifierfil0" cx="67.65" cy="60.64" r="2.55" />
              <circle class="emulsifierfil0" cx="61.88" cy="66.71" r="2.55" />
              <circle class="emulsifierfil0" cx="54.29" cy="70.84" r="2.55" />
              <circle class="emulsifierfil0" cx="45.23" cy="72.36" r="2.55" />
              <circle class="emulsifierfil0" cx="18.09" cy="45.22" r="2.55" />
              <circle class="emulsifierfil0" cx="19.19" cy="53.26" r="2.55" />
              <circle class="emulsifierfil0" cx="22.81" cy="60.64" r="2.55" />
              <circle class="emulsifierfil0" cx="28.58" cy="66.71" r="2.55" />
              <circle class="emulsifierfil0" cx="36.17" cy="70.84" r="2.55" />
              <circle class="emulsifierfil0" cx="71.25" cy="37.19" r="2.55" />
              <circle class="emulsifierfil0" cx="67.64" cy="29.81" r="2.55" />
              <circle class="emulsifierfil0" cx="61.87" cy="23.73" r="2.55" />
              <circle class="emulsifierfil0" cx="54.28" cy="19.61" r="2.55" />
              <circle class="emulsifierfil0" cx="45.22" cy="18.09" r="2.55" />
              <circle class="emulsifierfil0" cx="18.09" cy="45.22" r="2.55" />
              <circle class="emulsifierfil0" cx="19.19" cy="37.19" r="2.55" />
              <circle class="emulsifierfil0" cx="22.81" cy="29.81" r="2.55" />
              <circle class="emulsifierfil0" cx="28.58" cy="23.73" r="2.55" />
              <circle class="emulsifierfil0" cx="36.17" cy="19.61" r="2.55" />
              <g id="_2631748810992">
                <path
                  class="emulsifierfil1 emulsifierstr1"
                  d="M44.54 31.65c0,-3.39 1.36,-3.39 1.36,-6.78 0,-3.39 -1.36,-3.39 -1.36,-6.78"
                />
              </g>
              <g id="_2631748812144">
                <path
                  class="emulsifierfil1 emulsifierstr1"
                  d="M44.55 72.33c0,-3.39 1.35,-3.39 1.35,-6.77 0,-3.39 -1.35,-3.39 -1.35,-6.77"
                />
              </g>
              <g id="_2631748814544">
                <path
                  class="emulsifierfil1 emulsifierstr1"
                  d="M72.37 45.9c-3.4,0 -3.4,-1.36 -6.79,-1.36 -3.4,0 -3.4,1.36 -6.79,1.36"
                />
              </g>
              <g id="_2631748813632">
                <path
                  class="emulsifierfil1 emulsifierstr1"
                  d="M31.66 45.9c-3.39,0 -3.39,-1.36 -6.78,-1.36 -3.39,0 -3.39,1.36 -6.78,1.36"
                />
              </g>
              <g id="_2631748814352">
                <path
                  class="emulsifierfil1 emulsifierstr1"
                  d="M53.74 71.05c-1.14,-3.21 0.07,-3.64 -1.08,-6.84 -1.14,-3.21 -2.35,-2.78 -3.49,-5.98"
                />
              </g>
              <g id="_2631748815216">
                <path
                  class="emulsifierfil1 emulsifierstr1"
                  d="M40.08 32.65c-1.14,-3.2 0.07,-3.63 -1.07,-6.83 -1.14,-3.2 -2.35,-2.77 -3.48,-5.97"
                />
              </g>
              <g id="_2631748818480">
                <path
                  class="emulsifierfil1 emulsifierstr1"
                  d="M61.45 67.04c-2.09,-2.69 -1.24,-3.35 -3.32,-6.05 -2.09,-2.69 -2.94,-2.03 -5.03,-4.73"
                />
              </g>
              <g id="_2631748815984">
                <path
                  class="emulsifierfil1 emulsifierstr1"
                  d="M36.49 34.83c-2.08,-2.69 -1.23,-3.35 -3.32,-6.04 -2.08,-2.69 -2.93,-2.03 -5.02,-4.72"
                />
              </g>
              <g id="_2631748817088">
                <path
                  class="emulsifierfil1 emulsifierstr1"
                  d="M67.33 61.1c-2.81,-1.93 -2.17,-2.86 -4.99,-4.79 -2.81,-1.93 -3.45,-1.01 -6.26,-2.94"
                />
              </g>
              <g id="_2631748815696">
                <path
                  class="emulsifierfil1 emulsifierstr1"
                  d="M33.72 38c-2.81,-1.93 -2.17,-2.86 -4.98,-4.79 -2.81,-1.93 -3.44,-1 -6.25,-2.94"
                />
              </g>
              <g id="_2631748819728">
                <path
                  class="emulsifierfil1 emulsifierstr1"
                  d="M71.07 53.89c-3.27,-1.01 -2.88,-2.26 -6.15,-3.27 -3.27,-1.01 -3.65,0.24 -6.92,-0.77"
                />
              </g>
              <g id="_2631748818720">
                <path
                  class="emulsifierfil1 emulsifierstr1"
                  d="M32.06 41.85c-3.26,-1.01 -2.88,-2.26 -6.14,-3.26 -3.26,-1.01 -3.65,0.24 -6.91,-0.77"
                />
              </g>
              <g id="_2631748818672">
                <path
                  class="emulsifierfil1 emulsifierstr1"
                  d="M71.66 37.83c-3.32,1.02 -3.7,-0.25 -7.02,0.77 -3.32,1.02 -2.93,2.29 -6.24,3.3"
                />
              </g>
              <g id="_2631748819200">
                <path
                  class="emulsifierfil1 emulsifierstr1"
                  d="M32.46 49.87c-3.27,1.01 -3.65,-0.24 -6.92,0.76 -3.27,1.01 -2.88,2.25 -6.15,3.26"
                />
              </g>
              <g id="_2631748819440">
                <path
                  class="emulsifierfil1 emulsifierstr1"
                  d="M67.97 30.28c-2.81,1.93 -3.45,1 -6.26,2.93 -2.81,1.93 -2.17,2.86 -4.99,4.79"
                />
              </g>
              <g id="_2631748821024">
                <path
                  class="emulsifierfil1 emulsifierstr1"
                  d="M34.36 53.38c-2.81,1.93 -3.44,1 -6.25,2.94 -2.81,1.93 -2.17,2.86 -4.98,4.79"
                />
              </g>
              <g id="_2631748823088">
                <path
                  class="emulsifierfil1 emulsifierstr1"
                  d="M62.29 24.07c-2.08,2.69 -2.93,2.03 -5.02,4.72 -2.08,2.69 -1.23,3.35 -3.32,6.04"
                />
              </g>
              <g id="_2631748824096">
                <path
                  class="emulsifierfil1 emulsifierstr1"
                  d="M37.34 56.27c-2.08,2.69 -2.94,2.03 -5.02,4.73 -2.08,2.69 -1.23,3.35 -3.31,6.05"
                />
              </g>
              <g id="_2631748822320">
                <path
                  class="emulsifierfil1 emulsifierstr1"
                  d="M54.89 19.82c-1.13,3.21 -2.34,2.78 -3.48,5.99 -1.13,3.21 0.08,3.63 -1.06,6.84"
                />
              </g>
              <g id="_2631748821840">
                <path
                  class="emulsifierfil1 emulsifierstr1"
                  d="M41.3 58.22c-1.13,3.21 -2.34,2.78 -3.48,5.99 -1.13,3.21 0.08,3.63 -1.06,6.84"
                />
              </g>
            </g>
          </svg>
        </template>
        <template v-slot:base_title_active
          ><router-link v-on:click="scrollToTop" to="/cosmeticEmulsifier"
            >Эмульгаторы, эмульсионные базы
          </router-link>
        </template>
        <template v-slot:base_description>
          <div class="base_element">
            <li>Cetearyl alcohol, ceteareth-20</li>
          </div>
          <div class="base_element">
            <li>
              Cetearyl alcohol, polysorbate 60, PEG-100 stearate, ceteareth-25
            </li>
          </div>
          <div class="base_element">
            <li>
              Glyceryl stearate, ceteareth-20, ceteareth-20,
              ceteareth-12,cetearyl alcohol, cetyl palmitate
            </li>
          </div>
          <div class="base_element">
            <li>Glyceryl stearate, PEG-100 stearate</li>
          </div>
          <div class="base_element">
            <li>PEG-6 caprylic/capric glycerides</li>
          </div>
          <div class="base_element">
            <li>PEG-40 hydrogenated castor oil</li>
          </div>
          <div class="base_element"><li>Laureth-4</li></div>
          <div class="base_element"><li>Laureth-23</li></div>
        </template>
      </the-base-card-style>
      <!-- Card for Whitening Ingredients -->
      <the-base-card-style>
        <template v-slot:base_item></template>
        <template v-slot:base_icon>
          <!-- SVG Icon for Whitening Ingredients -->
          <svg
            class="svg_image"
            xmlns="http://www.w3.org/2000/svg"
            xml:space="preserve"
            width="18.75mm"
            height="18.75mm"
            version="1.1"
            style="
              shape-rendering: geometricPrecision;
              text-rendering: geometricPrecision;
              image-rendering: optimizeQuality;
              fill-rule: evenodd;
              clip-rule: evenodd;
            "
            viewBox="0 0 119.96 119.96"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xmlns:xodm="http://www.corel.com/coreldraw/odm/2003"
          >
            <g id="Слой_x0020_1">
              <metadata id="CorelCorpID_0Corel-Layer" />
              <g id="_2632825227696">
                <g>
                  <path
                    class="whiteningIngfil0"
                    d="M59.98 6.77c29.34,0 53.21,23.87 53.21,53.21 0,29.34 -23.87,53.21 -53.21,53.21 -29.34,0 -53.21,-23.87 -53.21,-53.21 0,-29.34 23.87,-53.21 53.21,-53.21l0 0zm0 -6.77c-33.13,0 -59.98,26.85 -59.98,59.98 0,33.13 26.85,59.98 59.98,59.98 33.13,0 59.98,-26.85 59.98,-59.98 0,-33.13 -26.85,-59.98 -59.98,-59.98l0 0z"
                  />
                </g>
              </g>
              <path
                class="whiteningIngfil1 whiteningIngstr0"
                d="M77.03 93.53c-4.63,3.67 -10.57,5.87 -17.05,5.87 -14.79,0 -26.78,-11.49 -26.78,-25.67 0,-12.62 21.24,-45.8 25.89,-52.91 0.42,-0.65 1.36,-0.65 1.79,0 2.91,4.45 12.29,19.07 18.93,32.5"
              />
              <polygon
                class="whiteningIngfil0 whiteningIngstr0"
                points="88.54,53.47 65.52,53.47 54.01,73.41 65.52,93.34 88.54,93.34 100.05,73.41 "
              />
              <path
                class="whiteningIngfil1 whiteningIngstr1"
                d="M90.78 69.06c0.43,1.37 0.67,2.83 0.67,4.34 0,7.3 -5.43,13.33 -12.47,14.28"
              />
              <path
                class="whiteningIngfil1 whiteningIngstr1"
                d="M65.13 65.27c2.6,-3.79 6.96,-6.28 11.9,-6.28 4.94,0 9.3,2.49 11.9,6.28"
              />
              <path
                class="whiteningIngfil1 whiteningIngstr1"
                d="M74.55 87.61c-6.78,-1.17 -11.94,-7.09 -11.94,-14.2 0,-1.5 0.23,-2.95 0.66,-4.31"
              />
            </g>
          </svg>
        </template>
        <template v-slot:base_title_active>
          <router-link v-on:click="scrollToTop" to="/whiteningIngredients"
            >Отбеливающие компоненты</router-link
          >
        </template>
        <template v-slot:base_description>
          <div class="base_element"><li>Genowhite</li></div>
          <div class="base_element"><li>Et-VC</li></div>
          <div class="base_element"><li>Azeclair & Azeclair P</li></div>
          <div class="base_element"><li>Kojic Acid</li></div>
          <div class="base_element"><li>Azelaic Acid</li></div>
          <div class="base_element"><li>Ferulic Acid</li></div>
          <div class="base_element"><li>DL-Magnetic Acid</li></div>
          <div class="base_element"><li>Kojic Acid dipalmitate</li></div>
          <div class="base_element"><li>Magnesium ascorbyl phosphate</li></div>
          <div class="base_element">
          <p>
            Полный список Отбеливающих компонентов смотрите в Разделе
            <router-link v-on:click="scrollToTop" to="/WhiteningIngredients"
              >Отбеливающие компоненты</router-link
            >
          </p></div>
        </template>
      </the-base-card-style>
      <!-- Card for Peptides -->
      <the-base-card-style>
        <template v-slot:base_item></template>
        <template v-slot:base_icon>
          <!-- SVG Icon for Peptides -->
          <svg
            class="svg_image"
            xmlns="http://www.w3.org/2000/svg"
            xml:space="preserve"
            width="18.75mm"
            height="18.75mm"
            version="1.1"
            style="
              shape-rendering: geometricPrecision;
              text-rendering: geometricPrecision;
              image-rendering: optimizeQuality;
              fill-rule: evenodd;
              clip-rule: evenodd;
            "
            viewBox="0 0 415.05 415.05"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xmlns:xodm="http://www.corel.com/coreldraw/odm/2003"
          >
            <g id="Слой_x0020_1">
              <metadata id="CorelCorpID_0Corel-Layer" />
              <g id="_2632800525664">
                <g>
                  <path
                    class="peptidefil0"
                    d="M207.52 23.43c101.51,0 184.1,82.58 184.1,184.1 0,101.51 -82.58,184.1 -184.1,184.1 -101.51,0 -184.1,-82.58 -184.1,-184.1 0,-101.51 82.58,-184.1 184.1,-184.1l0 0zm0 -23.43c-114.61,0 -207.52,92.91 -207.52,207.52 0,114.61 92.91,207.52 207.52,207.52 114.61,0 207.52,-92.91 207.52,-207.52 0,-114.61 -92.91,-207.52 -207.52,-207.52l0 0z"
                  />
                </g>
              </g>
              <circle
                class="peptidefil0 peptidestr0"
                cx="211.33"
                cy="92.18"
                r="22.58"
              />
              <circle
                class="peptidefil0 peptidestr0"
                cx="137.09"
                cy="161.62"
                r="22.58"
              />
              <circle
                class="peptidefil0 peptidestr0"
                cx="211.05"
                cy="204.31"
                r="22.58"
              />
              <circle
                class="peptidefil0 peptidestr0"
                cx="276.61"
                cy="242.85"
                r="22.58"
              />
              <circle
                class="peptidefil0 peptidestr0"
                cx="210.86"
                cy="309.4"
                r="22.58"
              />
              <line
                class="peptidefil1 peptidestr0"
                x1="193.16"
                y1="107.27"
                x2="153.92"
                y2="145.68"
              />
              <line
                class="peptidefil1 peptidestr0"
                x1="260.16"
                y1="260.03"
                x2="226.07"
                y2="293.39"
              />
              <line
                class="peptidefil1 peptidestr0"
                x1="232.93"
                y1="216.97"
                x2="256.32"
                y2="230.88"
              />
              <line
                class="peptidefil1 peptidestr0"
                x1="157.24"
                y1="171.94"
                x2="190.91"
                y2="191.97"
              />
            </g>
          </svg>
        </template>
        <template v-slot:base_title_active>
          <router-link v-on:click="scrollToTop" to="/thePeptides"
            >Пептиды</router-link
          >
        </template>
        <template v-slot:base_description>
          <div class="base_element">
            <li>SpecPed® GHK-Cu</li>
          </div>
          <div class="base_element">
            <li>SpecPed GHK2-Cu</li>
          </div>
          <div class="base_element">
            <li>SpecPed 3AK</li>
          </div>
          <div class="base_element">
            <li>SpecPed BT1 Hair Growth</li>
          </div>
          <div class="base_element">
            <li>SpecPed Sensi AD</li>
          </div>
          <div class="base_element">
            <li>SpecPed SC-PT38</li>
          </div>
          <div class="base_element">
            <li>SpecPed PluLip</li>
          </div>
          <div class="base_element">
            <li>SpecPed P4T7</li>
          </div>
          <div class="base_element">
            <li>SpecPed SC-PP18</li>
          </div>
          <div class="base_element">
            <li>SpecPed SC-AO3</li>
          </div>
          <div class="base_element">
            <li>SpecPed SC-AH8</li>
          </div>
          <!-- <div class="base_element">
            <p>
              Полный список пептидов смотрите в Разделе
              <router-link v-on:click="scrollToTop" to="/thePeptides"
                >Пептиды</router-link
              >.
            </p>
          </div> -->
        </template>
      </the-base-card-style>
    </template>
  </the-base-style>
</template>

<script>
export default {
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped>
.background_cosmetic {
  background-image: linear-gradient(
      to top,
      rgba(0, 132, 89, 1),
      rgba(0, 132, 89, 0.8)
    ),
    url(../../assets/cosmetic_industry.jpg);
  background-size: cover;
  position: relative;
  height: 80vh;
  background-position-y: 40%;
  clip-path: polygon(0 0, 100% 0%, 100% 91%, 0 100%, 0 100%);
}
/* SVG Icon Style */
.svg_image {
  width: 100px;
  height: auto;
  border: 3px solid transparent;
  border-radius: 50%;
  background-color: white;
}
/* Style for Active Ingredients Icon */
.activeIngrstr0 {
  stroke: #008259;
  stroke-width: 3.1;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 22.9256;
}
.activeIngrfil1 {
  fill: none;
  fill-rule: nonzero;
}
.activeIngrfil0 {
  fill: #008259;
  fill-rule: nonzero;
}
/* Style for Emollients Icon */
.emollient2 {
  fill: #008259;
  fill-rule: nonzero;
}
/* Style for FermentOil Icon */
.fermentOilstr0 {
  stroke: #008259;
  stroke-width: 2;
  stroke-miterlimit: 22.9256;
}
.fermentOilfil0 {
  fill: none;
}
.fermentOilfil1 {
  fill: #008259;
  fill-rule: nonzero;
}
.fermentOilfil2 {
  fill: white;
  fill-rule: nonzero;
}
/* Style for Emulsifiers Icon */
.emulsifierstr0 {
  stroke: #008259;
  stroke-width: 0.64;
  stroke-linecap: round;
  stroke-miterlimit: 22.9256;
}
.emulsifierstr1 {
  stroke: #008259;
  stroke-width: 1.28;
  stroke-linecap: round;
  stroke-miterlimit: 22.9256;
}
.emulsifierfil1 {
  fill: none;
  fill-rule: nonzero;
}
.emulsifierfil0 {
  fill: #008259;
  fill-rule: nonzero;
}
/* Style for Whitening Ingredients Icon */
.whiteningIngstr0 {
  stroke: #008259;
  stroke-width: 1.69;
  stroke-miterlimit: 22.9256;
}
.whiteningIngstr1 {
  stroke: white;
  stroke-width: 1.69;
  stroke-linecap: round;
  stroke-miterlimit: 22.9256;
}
.whiteningIngfil1 {
  fill: none;
  fill-rule: nonzero;
}
.whiteningIngfil0 {
  fill: #008259;
  fill-rule: nonzero;
}
/* Style for Peptide Icon */
.peptidestr0 {
  stroke: #008259;
  stroke-width: 5.86;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 22.9256;
}
.peptidefil1 {
  fill: none;
  fill-rule: nonzero;
}
.peptidefil0 {
  fill: #008259;
  fill-rule: nonzero;
}

/* Style for elements */
.base_element_active {
  list-style: none;
  text-decoration: none;
  color: rgba(0, 132, 89, 1);
  transition: all 0.2s ease-out;
}
.base_element_active:hover,
.base_element_active:active {
  color: #99cebd;
  cursor: pointer;
}
.base_element_active li {
  margin-bottom: 10px;
}
.base_element {
  font-size: 16px;
  list-style: none;
  text-decoration: none;
  color: rgba(0, 132, 89, 1);
  transition: all 0.2s ease-out;
}
.base_element li {
  margin-bottom: 3px;
}
.base_element p {
  color: black;
}
a {
  text-decoration: none;
  color: #008459;
  transition: all 0.2s ease-out;
}
a:hover,
a:active {
  color: #99cebd;
  cursor: pointer;
}
/* Media Queries */
/* Media Queries (max-width: 1350px) */
@media (max-width: 1350px) {
}
/* Media Queries (max-width: 1250px) */
@media (max-width: 1250px) {
}
/* Media Queries (max-width: 1200px) */
@media (max-width: 1200px) {
}
/* Media Queries (max-width: 998px) */
@media (max-width: 998px) {
}

/* Media Queries (max-width: 747px) */
@media (max-width: 747px) {
}
/* Media Queries (max-width: 500px) */
@media (max-width: 500px) {
}
</style>


















