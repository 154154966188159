<template>
  <the-base-product-list-style>
    <template v-slot: product></template>
    <template v-slot:product_header>
      <h3>Активные ингредиенты</h3>
    </template>
    <template v-slot:product_title>
      <h5>Комплексные активы</h5>
    </template>
    <template v-slot:product_list>
      <!-- AC Violet’Blond Toner -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>AC Violet’Blond Toner</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Dipsacus Sylvestris Extract & Viola Tricolor Flower Juice &
            Lactobacillus Ferment Lysate & Pisum Sativum (Pea) Starch
          </p>
        </template>
      </the-base-product-item-style>

      <!-- AC Blue’Nette Toner -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>AC Blue’Nette Toner</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Clitoria Ternatea Flower Extract & Lactobacillus Ferment
            Lysate & Pisum Sativum (Pea) Starch
          </p>
        </template>
      </the-base-product-item-style>

      <!-- AcquaSeal® Algae -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>AcquaSeal® Algae</h6>
        </template>
        <template v-slot:product_text>
          <p>INCI: Chlamydomonas Reinhardtii Extract</p>
        </template>
      </the-base-product-item-style>
      <!-- AC Cytopure PF -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>AC Cytopure PF</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Crypthecodinium Cohnii Extract & Leuconostoc/Radish Root
            Ferment Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- ACB Wakame Bioferment Advanced -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>ACB Wakame Bioferment Advanced</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Undaria Pinnatifida Cell Culture Extract & Leuconostoc/Radish
            Root Ferment Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- Phyto-Biotics Acai® -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Phyto-Biotics Acai®</h6>
        </template>
        <template v-slot:product_text>
          <p>INCI: Euterpe Oleracea Fruit Extract & Lactobacillus Ferment</p>
        </template>
      </the-base-product-item-style>
      <!-- Mycofus® Protect -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Mycofus® Protect</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Aqua & Lentinus Edodes Mycelium Extract & Lactobacillus
            Ferment
          </p>
        </template>
      </the-base-product-item-style>
      <!-- Phyto-Biotics Saffron -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Phyto-Biotics Saffron</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Aqua & Crocus Sativus Meristem Cell Extract & Lactobacillus
            Ferment
          </p>
        </template>
      </the-base-product-item-style>
      <!-- Phytofuse Renew® -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Phytofuse Renew®</h6>
        </template>
        <template v-slot:product_text>
          <p>INCI: Selaginella Lepidophylla Extract & Lactobacillus Ferment</p>
        </template>
      </the-base-product-item-style>
      <!-- Phytofuse Rejuvenate® -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Phytofuse Rejuvenate®</h6>
        </template>
        <template v-slot:product_text>
          <p>INCI: Salvia Hispanica Seed Extract & Lactobacillus Ferment</p>
        </template>
      </the-base-product-item-style>
      <!-- AC Griffonia Lysate Advanced -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>AC Griffonia Lysate Advanced</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Griffonia Simplicifolia Seed Extract & Leuconostoc/Radish Root
            Ferment Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- ACB Yerba Santa Glycoprotein PF -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>ACB Yerba Santa Glycoprotein PF</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Lactobacillus/Eriodictyon Californicum Ferment Extract &
            Leuconostoc/Radish Root Ferment Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- Revital-Eyes -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>Revital-Eyes</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Aqua & Lactobacillus Ferment Lysate & Camellia Sinensis Leaf
            Extract & Punica Granatum Extract & Lactobacillus Ferment & Caffeine
          </p>
        </template>
      </the-base-product-item-style>
      <!-- ProRevive BB Complex -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>ProRevive BB Complex</h6>
        </template>
        <template v-slot:product_text>
          <p>INCI: Lactobacillus/Salix Alba Bark Ferment Filtrate</p>
        </template>
      </the-base-product-item-style>
      <!-- AC Biopolymer Chia PF -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>AC Biopolymer Chia PF</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Coconut Acid & Salvia Hispanica Seed Extract &
            Leuconostoc/Radish Root Ferment Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- AC Sponge Lysate Advanced -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>AC Sponge Lysate Advanced</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Hydrolyzed Sponge Extract & Leuconostoc/Radish Root Ferment
            Filtrate
          </p>
        </template>
      </the-base-product-item-style>
      <!-- AC Hair and Scalp Complex -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>AC Hair and Scalp Complex</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Aqua & Saccharum Officinarum (Sugar Cane) Extract & Citrus
            Limon (Lemon) Fruit Extract & Betaine & Hexylene Glycol & Pyrus
            Malus (Apple) Fruit Extract & Camellia Sinensis Leaf Extract
            &Hexapeptide-11 & Phenoxyethanol
          </p>
        </template>
      </the-base-product-item-style>
      <!-- AC Dermal Blend PSB -->
      <the-base-product-item-style>
        <template v-slot:product_subtitle>
          <h6>AC Dermal Blend PSB</h6>
        </template>
        <template v-slot:product_text>
          <p>
            INCI: Aqua & Elastin & Glycerin & Soluble Collagen & Hyaluronic Acid
            & Propylene Glycol & Lactic Acid & Urea & Sorbitol & Triethylene
            Glycol & PEG 40 Hydrogenated Castor Oil & Trideceth-9 & Serine &
            Rosa Damascena (Rose) Extract & Rosa Damascena (Rose) Oil & Sodium
            Chloride & Sodium Hydroxide & Allantoin & Triethanolamine
          </p>
        </template>
      </the-base-product-item-style>
    </template>
  </the-base-product-list-style>
</template>















