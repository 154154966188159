<template>
  <div class="product_item">
    <div class="product_subtitle">
      <slot name="product_subtitle"></slot>
    </div>

    <div class="product_text">
      <slot name="product_text"></slot>
    </div>
  </div>
</template>

<style scoped>
.product_item {
  display: flex;
  height: auto;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 5px 15px;
  box-shadow: 0px 5px 14px 1px rgba(0, 132, 89, 0.2);
  transition: all 0.3s linear;
  
}
.product_item:hover {
  border: 1px solid rgba(0, 132, 89, 1);
  color: rgba(0, 132, 89, 1);
  cursor: pointer;
  transition: all 0.3s linear;
}
.product_item:hover .product_text {
  display: block;
}

.product_subtitle {
  list-style: square;
  font-size: 36px;
  display: contents;
  
  
}

.product_text {
  display: none;
  font-size: 18px;
  margin-bottom: 15px;
  text-align: justify;
  
  
}
</style>