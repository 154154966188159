<template>
  <div class="contacts">
    <div class="background_contacts polygon">
      <!-- <heptagon-layout></heptagon-layout> -->
      <div class="adress">
        
        <h2 class="adress_title">{{$t('contacts.adressTitle')}}</h2>
        <div class="adress_item">
          <h4>{{$t('contacts.workTime')}}</h4>
          <p>{{$t('contacts.workHours')}}</p>
        </div>
        <div class="adress_item">
          <h4>{{$t('contacts.adressLegal')}}</h4>
          <p>{{$t('contacts.nameCountry')}}</p>
          <p>{{$t('contacts.indexCity')}}</p>
          <p>{{$t('contacts.localAdress')}}</p>
        </div>
        <div class="adress_item">
          <h4>{{$t('contacts.adressPost')}}</h4>
          <p>{{$t('contacts.nameCountry')}}</p>
          <p>{{$t('contacts.indexCity')}}</p>
          <p>{{$t('contacts.localAdress')}}</p>
        </div>
      </div>
    </div>
    <div class="phone">
      <div class="phone_item">
        <div class="svg_item">
        <svg
          class="svg_image"
          viewBox="0 0 512 512"
          xmlns="http://www.w3.org/2000/svg"
          fill="#008459"
        >
          <title />
          <g data-name="1" id="_1">
            <path
              d="M348.73,450.06a198.63,198.63,0,0,1-46.4-5.85c-52.43-12.65-106.42-44.74-152-90.36s-77.71-99.62-90.36-152C46.65,146.75,56.15,99.61,86.69,69.07l8.72-8.72a42.2,42.2,0,0,1,59.62,0l50.11,50.1a42.18,42.18,0,0,1,0,59.62l-29.6,29.59c14.19,24.9,33.49,49.82,56.3,72.63s47.75,42.12,72.64,56.31L334.07,299a42.15,42.15,0,0,1,59.62,0l50.1,50.1a42.16,42.16,0,0,1,0,59.61l-8.73,8.72C413.53,439,383.73,450.06,348.73,450.06ZM125.22,78a12,12,0,0,0-8.59,3.56l-8.73,8.72c-22.87,22.87-29.55,60-18.81,104.49,11.37,47.13,40.64,96.1,82.41,137.86s90.73,71,137.87,82.41c44.5,10.74,81.61,4.06,104.48-18.81l8.72-8.72a12.16,12.16,0,0,0,0-17.19l-50.09-50.1a12.16,12.16,0,0,0-17.19,0l-37.51,37.51a15,15,0,0,1-17.5,2.72c-30.75-15.9-61.75-39.05-89.65-66.95s-51-58.88-66.94-89.63a15,15,0,0,1,2.71-17.5l37.52-37.51a12.16,12.16,0,0,0,0-17.19l-50.1-50.11A12.07,12.07,0,0,0,125.22,78Z"
            />
            <path
              d="M364.75,269.73a15,15,0,0,1-15-15,99.37,99.37,0,0,0-99.25-99.26,15,15,0,0,1,0-30c71.27,0,129.25,58,129.25,129.26A15,15,0,0,1,364.75,269.73Z"
            />
            <path
              d="M428.15,269.73a15,15,0,0,1-15-15c0-89.69-73-162.66-162.65-162.66a15,15,0,0,1,0-30c106.23,0,192.65,86.43,192.65,192.66A15,15,0,0,1,428.15,269.73Z"
            />
          </g>
        </svg>
        </div>
        <h4>{{$t('contacts.phone')}}</h4>
        <p class="description">+375173636600</p>
        <!-- <p class="description">+375445522995</p> -->
      </div>
      <div class="phone_item">
        <div class="svg_item">
        <svg
          class="svg_image"
          height="72"
          id="svg1025"
          version="1.1"
          viewBox="0 0 19.05 19.050001"
          width="72"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
          xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
          xmlns:svg="http://www.w3.org/2000/svg"
        >
          <defs id="defs1022" />
          <g id="layer1" style="opacity: 1">
            <g id="g13958">
              <path
                d="M 5.3569873,15.068599 H 3.9596966 c -1.0245803,0 -1.8494241,-0.824848 -1.8494241,-1.849437 V 7.3389453 c 0,-1.0245874 0.8248438,-1.8494368 1.8494241,-1.8494368 v 0 H 15.090304 c 1.024581,0 1.849423,0.8248494 1.849423,1.8494368 v 5.8802167 c 0,1.024588 -0.824842,1.849437 -1.849423,1.849437 h -1.396819"
                id="rect2561"
                style="
                  font-variation-settings: normal;
                  fill: none;
                  fill-opacity: 1;
                  fill-rule: evenodd;
                  stroke: #008459;
                  stroke-width: 0.9;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                  stroke-miterlimit: 4;
                  stroke-dasharray: none;
                  stroke-dashoffset: 0;
                  stroke-opacity: 1;
                  paint-order: markers fill stroke;
                  stop-color: #000000;
                "
              />
              <path
                d="M 5.3567285,5.3695917 V 2.532978 c 0,-0.2442472 0.1966257,-0.4408808 0.4408741,-0.4408808 v 0 h 7.4548094 c 0.244238,0 0.440873,0.1966336 0.440873,0.4408808 v 2.8366137"
                id="rect2587"
                style="
                  font-variation-settings: normal;
                  fill: none;
                  fill-opacity: 1;
                  fill-rule: evenodd;
                  stroke: #008459;
                  stroke-width: 0.9;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                  stroke-miterlimit: 4;
                  stroke-dasharray: none;
                  stroke-dashoffset: 0;
                  stroke-opacity: 1;
                  paint-order: markers fill stroke;
                  stop-color: #000000;
                "
              />
              <path
                d="M 3.769055,11.140773 H 15.28095"
                id="path2867"
                style="
                  font-variation-settings: normal;
                  fill: none;
                  fill-opacity: 1;
                  stroke: #008459;
                  stroke-width: 0.9;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                  stroke-miterlimit: 4;
                  stroke-dasharray: none;
                  stroke-dashoffset: 0;
                  stroke-opacity: 1;
                  paint-order: markers fill stroke;
                  stop-color: #000000;
                "
              />
              <path
                d="m 5.3567285,11.26102 v 5.256006 c 0,0.24424 0.1966257,0.440877 0.4408741,0.440877 v 0 h 7.4548094 c 0.244238,0 0.440873,-0.196637 0.440873,-0.440877 V 11.26102"
                id="path3075"
                style="
                  font-variation-settings: normal;
                  fill: none;
                  fill-opacity: 1;
                  fill-rule: evenodd;
                  stroke: #008459;
                  stroke-width: 0.9;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                  stroke-miterlimit: 4;
                  stroke-dasharray: none;
                  stroke-dashoffset: 0;
                  stroke-opacity: 1;
                  paint-order: markers fill stroke;
                  stop-color: #000000;
                "
              />
              <rect
                height="1.0202672"
                id="rect3206"
                ry="0.51013362"
                style="
                  font-variation-settings: normal;
                  fill: none;
                  fill-opacity: 1;
                  fill-rule: evenodd;
                  stroke: #008459;
                  stroke-width: 0.9;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                  stroke-miterlimit: 4;
                  stroke-dasharray: none;
                  stroke-dashoffset: 0;
                  stroke-opacity: 1;
                  paint-order: markers fill stroke;
                  stop-color: #000000;
                "
                width="1.2469847"
                x="3.9415386"
                y="7.273715"
              />
              <path
                d="M 6.5488735,12.545099 H 12.585412"
                id="path3695"
                style="
                  font-variation-settings: normal;
                  fill: none;
                  fill-opacity: 1;
                  stroke: #008459;
                  stroke-width: 0.9;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                  stroke-miterlimit: 4;
                  stroke-dasharray: none;
                  stroke-dashoffset: 0;
                  stroke-opacity: 1;
                  paint-order: markers fill stroke;
                  stop-color: #000000;
                "
              />
              <path
                d="M 6.5488735,14.044359 H 12.585412"
                id="path4571"
                style="
                  font-variation-settings: normal;
                  fill: none;
                  fill-opacity: 1;
                  stroke: #008459;
                  stroke-width: 0.9;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                  stroke-miterlimit: 4;
                  stroke-dasharray: none;
                  stroke-dashoffset: 0;
                  stroke-opacity: 1;
                  paint-order: markers fill stroke;
                  stop-color: #000000;
                "
              />
              <path
                d="M 6.5488735,15.543619 H 12.585412"
                id="path4573"
                style="
                  font-variation-settings: normal;
                  fill: none;
                  fill-opacity: 1;
                  stroke: #008459;
                  stroke-width: 0.9;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                  stroke-miterlimit: 4;
                  stroke-dasharray: none;
                  stroke-dashoffset: 0;
                  stroke-opacity: 1;
                  paint-order: markers fill stroke;
                  stop-color: #000000;
                "
              />
            </g>
          </g>
        </svg>
        </div>
        <h4>{{$t('contacts.fax')}}</h4>
        <p class="description">+375173636660</p>
      </div>
      <div class="phone_item">
        <div class="svg_item">
        <svg
          class="svg_image"
          xmlns="http://www.w3.org/2000/svg"
          width="192"
          height="192"
          fill="none"
          viewBox="0 0 256 256"
        >
          <circle
            cx="128"
            cy="128"
            r="40"
            fill="none"
            stroke="#008459"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="15"
          ></circle>
          <path
            d="M181.1,208A96,96,0,1,1,224,128c0,22.1-8,40-28,40s-28-17.9-28-40V88"
            fill="none"
            stroke="#008459"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="15"
          ></path>
        </svg>
        </div>
        <h4>{{$t('contacts.eMail')}}</h4>
        <p class="description">i.shonorova@greenchemi.by</p>
        <p class="description">info@greenchemi.by</p>
        <p class="description">declarant@greenchemi.by</p>
      </div>
    </div>
  </div>
</template>


<style scoped>
.background_contacts {
  background-image: linear-gradient(
      to top,
      rgba(0, 132, 89, 1),
      rgba(0, 132, 89, 0.8)
    ),
    url(../assets/contacts.jpg);
  background-size: cover;
  position: relative;
  height: 110vh;
  background-position-y: 75%;
}
.polygon {
  clip-path: polygon(0 0, 100% 0%, 100% 91%, 0 100%, 0 100%);
}
/* ADRESS */
.adress {
  display: flex;
  flex-direction: column;
  max-width: 700px;
  color: white;
  font-family: "Roboto";
  padding-top: 150px;
  position: relative;
}
.adress_title {
  font-size: 3.2rem;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 20px;
}
.adress_item {
  margin-bottom: 20px;
  font-size: 2.2rem;
  font-family: "Roboto";
  text-align: center;
}
/* PHONE */
.phone{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 25px;
  max-width: 75%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
}
.phone_item {
  display: flex;
  height: 415px;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 0 15px;
  box-shadow: 0px 5px 14px 1px rgba(0, 132, 89, 0.2);
  transition: all 0.3s linear;
}

.phone_item:hover {
  cursor: pointer;
}
.phone_item:hover .description {
  display: block;
}
.phone_item:hover .svg_item {
  margin-top: -25px;
  margin-bottom: 65px;
}
.phone_item h4 {
  color: rgba(0, 132, 89, 1);
  text-transform: uppercase;
  text-align: center;
  font-size: 2.2rem;
  margin-bottom: 25px;
}
.svg_item{
  margin-top: 90px;
  margin-bottom: 35px;
  transition: all 0.5s ease-out;
}
.svg_image {
  display: block;
  width: 55px;
  height: 55px;
  margin-left: auto;
  margin-right: auto;
  border: 3px solid rgba(0, 132, 89, 1);
  border-radius: 50%;
  padding: 5px;
  background-color: white;
}
.description{
  display: none;
  font-size: 1.8rem;
  color: #666666;
  text-align: center;
  margin-bottom: 10px;
  transition: all 0.5s;
}
/* Media Queries Section */
/* Media Queries 1350px */
@media (max-width: 1350px) {
  
}
/* Media Queries 1250px */
@media (max-width: 1250px) {
  .phone{
    max-width: 90%;
  }
  .phone_item {
    height: 400px;
  }
  
}
/* Media Queries 1200px */
@media (max-width: 1200px) {
  .adress_title{
    font-size: 3rem;
  }
  .adress_item{
    font-size: 2rem;
  }
  .phone{
    max-width: 95%;
  }
  .phone_item {
    height: 350px;
  }
  .phone_item h4 {
    font-size: 2rem;
  }
}
/* Media Queries 998px */
@media (max-width: 998px) {
  .adress_title{
    font-size: 2.6rem;
  }
  .adress_item{
    font-size: 1.8rem;
  }
  .phone {
    grid-template-columns: 1fr;
    max-width: 65%;
  }
  .phone_item {
    height: 300px;
  }
  .phone_item h4 {
    font-size: 1.8rem;
  }
}
/* Media Queries 747px */
@media (max-width: 747px) {
  .background_contacts{
    height: 100vh;
  }
  .adress_title{
    font-size: 2.4rem;
  }
  .adress_item{
    font-size: 1.6rem;
  }
  .phone{
    max-width: 75%;
  }
  
  .phone_item h4 {
    font-size: 1.6rem;
  }
}
/* Media Queries 500px */
@media (max-width: 500px) {
  
  .phone{
    max-width: 95%;
  }
  
}

</style>